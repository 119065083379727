import { useCallback, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import JSONPretty from 'react-json-pretty';
import AppNavbar from '../../components/layout/AppNavbar';
import OriginalTextToolbar from "./components/OriginalTextToolbar";
import PrettyJsonToolbar from "./components/PrettyJsonToolbar";


const Home = () => {

    const [ rawJson, setRawJson ] = useState("");
    const [ validJson, setValidJson ] = useState("");

    const prettyfyJson = useCallback((rawText: string) => {

        setRawJson(rawText);

        // validate
        try {
            const isJsonValid = JSON.parse(rawText);

            if (!!isJsonValid) {
                // const formattedText = prettyPrintJson.toHtml(rawText);
                setValidJson(rawText);
            }
        } catch {
            console.log("invalid json");
        }
    }, [ setRawJson, setValidJson ]);

    const removeBackslashes = useCallback(() => {
        const cleanedJson = rawJson.replaceAll('\\"', '"');
        console.log("cleaned json", cleanedJson);
        setRawJson(cleanedJson);
        prettyfyJson(cleanedJson);
    }, [prettyfyJson, rawJson]);

    return (
        <div>
            <AppNavbar />

            <Container className="my-3" >
                <Row>
                    <Col sm="12" md="6">
                        <h5>Original Json</h5>
                        
                        <OriginalTextToolbar removeBackslashes={removeBackslashes} />

                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" rows={15} value={rawJson} onChange={(ev) => prettyfyJson(ev.target.value)} />
                            </Form.Group>
                        </Form>
                    </Col>
                    
                    <Col sm="12" md="6" >
                        <h5>Pretty Json</h5>

                        <PrettyJsonToolbar clear={() => setValidJson("")} />

                        <JSONPretty id="json-pretty" data={validJson}></JSONPretty>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default Home;