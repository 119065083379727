import Home from './pages/home/Home';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Home />
  );
}

export default App;
