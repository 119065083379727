
import React from "react";
import { Button } from "react-bootstrap";

interface PrettyJsonToolbarProps {
    clear(): void;
}

const PrettyJsonToolbar = (props: PrettyJsonToolbarProps) => {
    return (
        <div className="my-3" >
            <Button variant="secondary" size="sm" onClick={props.clear} >Clear</Button>
        </div>

    );
};

export default PrettyJsonToolbar;