import React from "react";
import { Container, Navbar } from "react-bootstrap";

const AppNavbar = () => {
    return (
        <Navbar variant="dark" style={{ backgroundColor: "#0078D4"}}>
                <Container>
                    <Navbar.Brand href="#home">JsonFormatter</Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        By <a href="https://muni.dev" target="_blank" rel="noreferrer">muni.dev</a>
                    </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
    );
};

export default AppNavbar;