
import React from "react";
import { Button } from "react-bootstrap";

interface OriginalTextToolbarProps {
    removeBackslashes(): void;
}

const OriginalTextToolbar = (props: OriginalTextToolbarProps) => {
    return (
        <div className="my-3" >
            <Button variant="secondary" size="sm" onClick={props.removeBackslashes} >Remove backslashes</Button>
        </div>

    );
};

export default OriginalTextToolbar;